import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import GiveButton from "../Shared/giveButton"
import Plant from "../../images/svg/plant.svg"

const DonationsBanner = () => {
    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 20 },
      }
    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 1.2 }}
        >
            <BannerWrapper>
                <div className="flex-container pos-rel">
                    <div className="container small padding-top-120 text-color-black pos-rel">
                        <h2 className="headline-xxxxl padding-bottom-60">
                            DONATE
                        </h2>
                        <h4 className="subhead-m padding-bottom-60">We are able to operate and serve our community because of the generosity of everyday people like you. 
                        Thank you so much for supporting Amplified and believing in what we've been called to do.</h4>
                        <Plant className="plant"/> 
                        <GiveButton />
                        <div className="con">
                            <p className="body-text-m margin-top-60 blocked">Alternatively we accept <span className="bold">e-transfers</span> through our email: <span className="bold">give@amplifiedchurch.ca</span></p>
                            <p className="body-text-m blocked small">OR </p>
                            <p className="body-text-m blocked">You can always give through debit/ credit/ cash in person at our services.</p>
                        </div>
                    </div>
                </div>
            </BannerWrapper>
        </motion.div>
    )
}

const BannerWrapper = styled.div`
min-height: 100vh;

.bold{
    font-weight: bold;
}

.heading{
    z-index: 1;
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}
.front-graphic{
    z-index: 0;
    margin-top: 3rem;
    height: auto;
    width: 100%;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.plant{
    height: 15rem;
    position: absolute;
    margin: 10%;
    left: 70%;
    filter: invert(0.6);
    z-index: -1;

    @media only screen and (max-width:767px){
        display: none;
    }
    @media only screen and (max-width:850px){
        left: 40%;
    }
}
.hugging-image{
    position: relative;
    z-index: -1;
    height: 50%;
    width: auto;
    left: 80%;
    bottom: 80%;
}
.blocked{
    //background-color: #f8f4f1;
    margin-right: 10rem;
    &.small{
        width: 10%;
    }
    @media only screen and (max-width:850px){
        margin-right: 0;
    }
}
.con{
    display: flex;
    flex-direction: column;
}
`
export default DonationsBanner