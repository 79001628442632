import React from 'react'

const GiveButton = () => {
    return(
        <a
            className="button"
            target="_blank" 
            rel="noreferrer" 
            aria-label="PayPal Link"
            href="https://www.paypal.com/donate?hosted_button_id=57464KD6WULPU"
            >
            Donate
        </a>
    )
}
export default GiveButton